"use client"

import { useRef } from "react"
import { clsx } from "clsx"

import { CardImageTitleZoom } from "~/components/ui/CardImageTitleZoom"
import { PopinZoomSlider } from "~/components/ui/PopinZoomSlider"
import SliderComponent from "~/components/ui/Slider"
import { type EditoCardsZoomSliderProps } from "~/components/slices/EditoCardsZoomSlider"
import { usePopin } from "~/managers/PopinManager"

import { sprinkles } from "~/styles/sprinkles.css"
import { grid } from "~/styles/variants"

import * as css from "./styles.css"

export default function ClientEditoCardsZoomSlider({ title, layout, cards }: EditoCardsZoomSliderProps) {
  const customSliderRef = useRef<HTMLDivElement | null>(null)
  const { add } = usePopin()

  const handleClick = (index: number) => {
    add(<PopinZoomSlider data={cards} currentIndex={index} />)
  }

  return (
    <div
      ref={customSliderRef}
      className={clsx(grid({ type: "fluid" }), sprinkles({ overflow: "hidden", marginTop: 30 }))}
      data-comp="Slices/EditoCardsZoomSlider"
    >
      <SliderComponent
        containerProps={{
          className: css.EditoCardsZoomSlider,
        }}
        sliderContainerClassName={css.sliderContainer}
        sliderWrapperClassName={sprinkles({ position: "relative" })}
        title={{
          className: css.title,
          content: title,
        }}
        arrowNavigationProps={{
          showFrom: "tablet",
        }}
        arrowNavigationPosition="center"
      >
        {cards.map((card, index) => (
          <button key={`card_${index}`} onClick={() => handleClick(index)}>
            <CardImageTitleZoom
              className={css.cardItem}
              customRatio={{ tablet: "5/8", mobile: "5/9" }}
              {...card}
              priority={index === 0}
              layout={layout}
            />
          </button>
        ))}
      </SliderComponent>
    </div>
  )
}
