"use client"

import type { ComponentPropsWithoutRef } from "react"
import { Slot } from "@radix-ui/react-slot"
import clsx from "clsx"
import { AnimatePresence, m } from "framer-motion"

import Icon from "~/components/abstracts/Icon"
import createStackComponentManager, { type ElementProps } from "~/components/abstracts/StackComponentManager"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { fromBezierToArray } from "~/utils/from-bezier-to-array"

import { sprinkles, type Sprinkles } from "~/styles/sprinkles.css"
import { easings } from "~/styles/variables/easings"

const [PopinManager, AbstractPopin, usePopin] = createStackComponentManager()

type PopinProps = ElementProps & {
  zIndex?: Sprinkles["zIndex"]
  bgColor?: Sprinkles["backgroundColor"]
}

function Popin({ zIndex = 50, bgColor = "blue-deep/50", className, children, ...props }: PopinProps) {
  return (
    <AbstractPopin className={clsx(sprinkles({ zIndex, bgColor }), className)} {...props}>
      <AnimatePresence>
        <m.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.25,
              ease: fromBezierToArray(easings["easeInQuad"]),
            },
          }}
          className={sprinkles({ height: "100%", width: "100%", display: "flex" })}
        >
          {children}
        </m.div>
      </AnimatePresence>
    </AbstractPopin>
  )
}

type PopinCloseButtonProps = {
  asChild?: boolean
  onRemove?: () => void
  sprinklesCss?: Sprinkles
} & ComponentPropsWithoutRef<"button">

function PopinCloseButton({ asChild, onRemove, className, sprinklesCss, children, ...props }: PopinCloseButtonProps) {
  const t = useTranslate()
  const { removeCurrent } = usePopin()

  const Comp = asChild ? Slot : "button"

  return (
    <Comp
      onClick={() => {
        removeCurrent()
        onRemove?.()
      }}
      aria-label={t("aria_popin_close")}
      className={clsx(
        sprinkles({
          position: "absolute",
          top: 0,
          right: 0,
          p: 20,
          zIndex: 20,
          cursor: "pointer",
          ...(sprinklesCss ?? {}),
        }),
        className
      )}
      {...props}
    >
      {children ?? <Icon name="Cross" theme="blue-deep" width={18} />}
    </Comp>
  )
}

Popin.CloseButton = PopinCloseButton

export { PopinManager, usePopin, Popin }
