"use client"

import type { InferReturn } from "~/@types/generics"
import RichText from "~/components/ui/RichText"
import type serializeEditoWysiwygSlice from "~/components/slices/EditoWYSIWYG/_data/serializer"
import { useUSPBannerHeight } from "~/managers/USPBannerManager"

import { NAVIGATION_FULL_DESKTOP_HEIGHT } from "~/styles/variables/elements"

type EditoWYSIWYGClientProps = InferReturn<typeof serializeEditoWysiwygSlice>

export function EditoWYSIWYGClient({ wysiwyg }: EditoWYSIWYGClientProps) {
  const uspBannerHeight = useUSPBannerHeight()[0]

  return (
    <RichText
      render={wysiwyg}
      anchorProps={{
        style: {
          scrollMarginTop: NAVIGATION_FULL_DESKTOP_HEIGHT + uspBannerHeight,
        },
      }}
    />
  )
}
