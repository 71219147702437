import { z } from "zod"

import { richtextSchema } from "~/components/ui/RichText/_data/schema"
import { defaultSliceSchema } from "~/components/slices/constants"

export const editoReadMoreSliceSchema = defaultSliceSchema("edito_read_more").extend({
  title: richtextSchema,
  text: richtextSchema,
  read_more_label: z.string(),
  read_less_label: z.string(),
  max_height: z.string().nullish().default("200"),
})
