"use client"

import { useRef } from "react"
import { clsx } from "clsx"

import { CardImageTitle } from "~/components/ui/CardImageTitle"
import SliderComponent from "~/components/ui/Slider"
import type { EditoCardsSliderProps } from "~/components/slices/EditoCardsSlider"

import { sprinkles } from "~/styles/sprinkles.css"
import { grid, text } from "~/styles/variants"

import * as css from "./styles.css"

export default function ClientEditoCardsSlider({ title, layout, cards, isFirstSlice }: EditoCardsSliderProps) {
  const customSliderRef = useRef<HTMLDivElement | null>(null)

  return (
    <div
      ref={customSliderRef}
      className={clsx(grid({ type: "fluid" }), sprinkles({ overflow: "hidden", marginTop: 30 }))}
      data-comp="Slices/EditoCardsSlider"
    >
      <SliderComponent
        containerProps={{
          gap: 30,
          className: css.EditoCardsSlider,
        }}
        title={{
          className: text({
            design: "spezia-24-32",
            lineHeight: 1.2,
            letterSpacing: 0.4,
            transform: "uppercase",
            color: "blue-deep",
          }),
          content: title,
        }}
        enableSliderBullets={true}
        arrowNavigationPosition="center"
        sliderBulletProps={{
          sprinklesCss: {
            mX: "auto",
            mB: 30,
          },
        }}
      >
        {cards.map((card, index) => (
          <CardImageTitle
            className={css.cardItem}
            customRatio={{ tablet: "5/8", mobile: "5/9" }}
            key={`card_${index}`}
            {...card}
            priority={isFirstSlice ? index < 4 : false}
            layout={layout}
          />
        ))}
      </SliderComponent>
    </div>
  )
}
