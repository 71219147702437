"use client"

import type { InferReturn } from "~/@types/generics"
import { CardEdito } from "~/components/ui/CardEdito"
import ProductCard from "~/components/ui/Product/ProductCard"
import { ProductCardsSlider } from "~/components/ui/ProductCardsSlider"
import type serializeProductSliderSlice from "~/components/slices/ProductSlider/_data/serializer"

export type ProductSliderProps = InferReturn<typeof serializeProductSliderSlice>

export function ProductSlider({ title, items }: ProductSliderProps) {
  return (
    <ProductCardsSlider
      data-comp="Slices/ProductSlider"
      title={title}
      sprinklesCss={{ pY: { mobile: 30, tablet: 60 } }}
      trackingListName="Slice: Product Slider"
    >
      {({ className }) => {
        return items.map((item, index) => {
          return item.type === "product" ? (
            <ProductCard
              className={className}
              key={`product-card-${index}`}
              product={item.content}
              priority={index === 0}
              mobileSliderDisplay={false}
              trackingData={{ index: index + 1, item_list_name: "Slice: Product Slider" }}
              sizesFromBreakpoint={[{ ratio: 1 }, { breakpoint: "md", ratio: 1 / 4 }]}
            />
          ) : (
            <CardEdito
              key={`edito-card-edito-${index}`}
              className={className}
              {...item.content}
              sizesFromBreakpoint={[{ ratio: 1 / 2 }, { ratio: 4 / 12, breakpoint: "lg" }]}
            />
          )
        })
      }}
    </ProductCardsSlider>
  )
}
