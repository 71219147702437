import type { z } from "zod"

import serializeImage from "~/components/ui/Image/_data/serializer"
import type { partnersSliderSliceSchema } from "~/components/slices/PartnersSlider/_data/schema"

type PartnersSliderSliceSchema = z.infer<typeof partnersSliderSliceSchema>

export default function serializePartnersSliderSlice(slice: PartnersSliderSliceSchema) {
  return {
    title: slice.title,
    items: slice.items.map((item) => ({ image: serializeImage(item.image) })),
  }
}
