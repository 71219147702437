"use client"

import { useRef } from "react"
import clsx from "clsx"

import type { InferReturn, PropsWithClassName } from "~/@types/generics"
import { Image } from "~/components/ui/Image"
import RichText from "~/components/ui/RichText"
import RoundedCta from "~/components/ui/RoundedCta"
import SliderComponent from "~/components/ui/Slider"
import Icon from "~/components/abstracts/Icon"
import type serializeEditoCardsZoomSliderSlice from "~/components/slices/EditoCardsZoomSlider/_data/serializer"
import { Popin } from "~/managers/PopinManager"

import { sprinkles } from "~/styles/sprinkles.css"
import { breakpoints } from "~/styles/variables/breakpoints"

import * as css from "./styles.css"

type PopinZoomSliderProps = PropsWithClassName<{
  data: InferReturn<typeof serializeEditoCardsZoomSliderSlice>["cards"]
  currentIndex: number
}>

const IMAGE_SIZES = [{ breakpoint: breakpoints.md, ratio: 679 / 796 }, { ratio: 375 / 439 }]

function PopinZoomSlider({ className, data, currentIndex }: PopinZoomSliderProps) {
  const popinRef = useRef(null)

  return (
    <Popin clickOutsideRef={popinRef} className={clsx(className)} data-comp="UI/PopinZoomSlider">
      <div className={css.wrapper}>
        <Popin.CloseButton asChild sprinklesCss={{ right: 15, top: 15, p: 0 }}>
          <RoundedCta theme={"grey-bg-page"}>
            <Icon name="Cross" theme="blue-deep" width={14} height={14} />
          </RoundedCta>
        </Popin.CloseButton>

        <div className={css.content} ref={popinRef}>
          <SliderComponent
            sliderContainerClassName={sprinkles({ h: "100%" })}
            sliderProps={{ className: sprinkles({ h: "100%" }) }}
            containerProps={{ sprinklesCss: { h: "100%" } }}
            arrowNavigationPosition="center"
            arrowNavigationProps={{
              leftArrowClassName: css.leftArrow,
              rightArrowClassName: css.rightArrow,
            }}
            sliderWrapperClassName={css.sliderWrapper}
          >
            {data[currentIndex]?.itemsSlider.map((item, index) => (
              <div className={css.item} key={`zoom-item-${index}`}>
                <Image
                  {...item.image}
                  asPlaceholder
                  className={sprinkles({ h: { tablet: "100%" }, w: "100%" })}
                  imageClassName={sprinkles({ w: "100%" })}
                  ratio={{ tablet: "679/796", mobile: "375/439" }}
                  sizesFromBreakpoints={IMAGE_SIZES}
                />
                <RichText render={item.credit} className={css.credit} />
              </div>
            ))}
          </SliderComponent>
        </div>
      </div>
    </Popin>
  )
}

export { PopinZoomSlider, type PopinZoomSliderProps }
