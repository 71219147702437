import { z } from "zod"

export const sbImageSchema = z.object({
  alt: z.string().nullish(),
  filename: z.string().url(),
})

export const shopifyImageSchema = z.object({
  altText: z.string().nullish(),
  url: z.string().url(),
  width: z.number().nullish(),
  height: z.number().nullish(),
})

export const imageSchema = z.union([sbImageSchema, shopifyImageSchema])

export const optionalImageSchema = z.object({ image: sbImageSchema })

export type ImageFromSchema = z.infer<typeof imageSchema>
