import { px } from "~/styles/utils/px"

const NAVIGATION_HEIGHT = 60
const NAVIGATION_DESKTOP_TOP = 20
const NAVIGATION_MOBILE_TOP = 10
const NAVIGATION_MOBILE_PANEL_FOOTER_HEIGHT = 56
const NAVIGATION_MOBILE_TABS_HEIGHT = 54

export const NAVIGATION_FULL_DESKTOP_HEIGHT = NAVIGATION_HEIGHT + NAVIGATION_DESKTOP_TOP * 2
const NAVIGATION_FULL_MOBILE_HEIGHT = NAVIGATION_HEIGHT + NAVIGATION_MOBILE_TOP * 2

export const elements = {
  navigation: {
    height: px(NAVIGATION_HEIGHT),
    desktopTop: px(NAVIGATION_DESKTOP_TOP),
    mobileTop: px(NAVIGATION_MOBILE_TOP),
    mobilePanelFooterHeight: px(NAVIGATION_MOBILE_PANEL_FOOTER_HEIGHT),
    fullDesktopHeight: px(NAVIGATION_FULL_DESKTOP_HEIGHT),
    fullMobileHeight: px(NAVIGATION_FULL_MOBILE_HEIGHT),
    tabsHeight: px(NAVIGATION_MOBILE_TABS_HEIGHT),
    uspBannerMobileMinHeight: "45px",
    uspBannerDesktopMinHeight: "30px",
  },
} as const
