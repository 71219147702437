import { z } from "zod"

export function officialColorpickerSchema() {
  return z
    .object({
      color: z.string().startsWith("#").optional().catch(undefined),
      plugin: z.literal("official-colorpicker"),
    })
    .transform((value) => value.color)
}
