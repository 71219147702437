"use client"

import type { InferReturn } from "~/@types/generics"
import { separatorSliceSchema } from "~/components/slices/Separator/_data/schema"
import serializeSeparatorSlice from "~/components/slices/Separator/_data/serializer"
import { WithSafeSliceData } from "~/components/slices/WithSafeSliceData"

import * as css from "./styles.css"

export type SeparatorProps = InferReturn<typeof serializeSeparatorSlice>

function Separator({ type, backgroundColor }: SeparatorProps) {
  return (
    <aside style={{ backgroundColor: backgroundColor ? backgroundColor : "" }} className={css.separator({ type })} />
  )
}

export default WithSafeSliceData(
  async function (props) {
    const serializedProps = await serializeSeparatorSlice(props)

    return <Separator {...serializedProps} />
  },
  separatorSliceSchema,
  "Separator"
)
