import type { z } from "zod"

import type { separatorSliceSchema } from "~/components/slices/Separator/_data/schema"

type SeparatorSliceSchema = z.infer<typeof separatorSliceSchema>

export default async function serializeSeparatorSlice(slice: SeparatorSliceSchema) {
  return {
    type: slice.type,
    backgroundColor: slice.background_color,
  }
}
