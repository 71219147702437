import type { z } from "zod"

import type { editoReadMoreSliceSchema } from "~/components/slices/EditoReadMore/_data/schema"

type EditoReadMoreSliceSchema = z.infer<typeof editoReadMoreSliceSchema>

export default function serializeEditoReadMoreSlice(slice: EditoReadMoreSliceSchema) {
  return {
    title: slice.title,
    text: slice.text,
    readMoreLabel: slice.read_more_label,
    readLessLabel: slice.read_less_label,
    maxHeight: Number(slice.max_height) ?? 200,
  }
}
