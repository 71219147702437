"use client"

import { useRef, type ComponentProps } from "react"
import { clsx } from "clsx"

import type { Nullish } from "~/@types/generics"
import ProductCard from "~/components/ui/Product/ProductCard"
import type { TProductCard } from "~/components/ui/Product/ProductCard/_data/serializer"
import type { SbRichText } from "~/components/ui/RichText/_data/schema"
import SliderComponent from "~/components/ui/Slider"
import type { ViewedItemListPayloadListName } from "~/providers/GTMTrackingProvider/constants"

import { sprinkles, type Sprinkles } from "~/styles/sprinkles.css"
import { grid, text } from "~/styles/variants"

import * as css from "./style.css"

export type ProductCardsSliderProps = {
  title: Nullish<SbRichText | string>
  sprinklesCss?: Omit<Sprinkles, "overflow">
  layout?: "default" | "small"
  trackingListName: ViewedItemListPayloadListName
} & Omit<ComponentProps<"div">, "title" | "children"> &
  (
    | { children?: never; cards: TProductCard[] }
    | { children: ({ className }: { className: string }) => React.ReactNode[]; cards?: never }
  )

export function ProductCardsSlider({
  title,
  cards,
  sprinklesCss,
  layout = "default",
  trackingListName,
  children,
  ...props
}: ProductCardsSliderProps) {
  const customSliderRef = useRef<HTMLDivElement | null>(null)

  return (
    <div
      ref={customSliderRef}
      className={clsx(grid({ type: "fluid" }), sprinkles({ overflow: "hidden", ...(sprinklesCss ?? {}) }))}
      data-comp="UI/ProductCardsSlider"
      {...props}
    >
      <SliderComponent
        customSliderRef={customSliderRef}
        containerProps={{
          gap: 30,
          className: css.ProductCardsSlider,
        }}
        title={{
          className: text({
            design: "spezia-24-32",
            lineHeight: 1.2,
            letterSpacing: 0.4,
            transform: "uppercase",
            color: "blue-deep",
          }),
          content: title,
        }}
        arrowNavigationPosition="center"
        arrowNavigationProps={{
          showFrom: "tablet",
        }}
      >
        {children
          ? children({ className: css.cardItem[layout] })
          : cards.map((item, index) => (
              <ProductCard
                className={css.cardItem[layout]}
                key={`product-card-${index}`}
                product={item}
                priority={index === 0}
                mobileSliderDisplay={false}
                trackingData={{ index: index + 1, item_list_name: trackingListName }}
                sizesFromBreakpoint={[{ ratio: 1 }, { breakpoint: "md", ratio: layout === "default" ? 1 / 4 : 1 / 3 }]}
              />
            ))}
      </SliderComponent>
    </div>
  )
}
