"use client"

import clsx from "clsx"

import type { InferReturn } from "~/@types/generics"
import { CARD_RATIO } from "~/lib/constants"
import { CardEdito } from "~/components/ui/CardEdito"
import ProductCard from "~/components/ui/Product/ProductCard"
import { Ratio } from "~/components/abstracts/Ratio"
import type serializeFourProductCardsSlice from "~/components/slices/FourProductCards/_data/serializer"

import { sprinkles } from "~/styles/sprinkles.css"

import * as css from "./styles.css"

export type FourProductCardsProps = InferReturn<typeof serializeFourProductCardsSlice>

export function FourProductCards({ items }: FourProductCardsProps) {
  if (!items.length) return null
  return (
    <div className={css.FourProductCards} data-comp="Slices/FourProductCards">
      {items.map((item, i) => {
        return item.type === "product" ? (
          <ProductCard
            key={`four-product-card-${i}`}
            layout="small"
            className={clsx(css.card)}
            sliderItemClassName={css.sliderItem}
            product={item.content}
            sizesFromBreakpoint={[{ ratio: 1 }, { ratio: 1 / 4, breakpoint: "md" }]}
            trackingData={{ index: i + 1, item_list_name: "Slice: Four Product Cards" }}
          />
        ) : (
          <Ratio
            key={`four-product-card-edito-${i}`}
            ratio={sprinkles({ ratio: CARD_RATIO })}
            className={clsx(css.card)}
          >
            {(style) => (
              <div style={style}>
                <CardEdito
                  {...item.content}
                  sizesFromBreakpoint={[{ ratio: 1 }, { ratio: 1 / 4, breakpoint: "md" }]}
                  sprinklesCss={{ h: "100%" }}
                />
              </div>
            )}
          </Ratio>
        )
      })}
    </div>
  )
}
