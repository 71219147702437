"use client"

import clsx from "clsx"

import type { InferReturn } from "~/@types/generics"
import { Asset } from "~/components/ui/Asset"
import type serializeCardImageTitleZoomSchema from "~/components/ui/CardImageTitleZoom/_data/serializer"
import { type Sizes } from "~/components/ui/Image"
import RichText from "~/components/ui/RichText"
import RoundedCta, { type RoundedCtaProps } from "~/components/ui/RoundedCta"

import type { Sprinkles } from "~/styles/sprinkles.css"
import { breakpoints } from "~/styles/variables/breakpoints"
import { text } from "~/styles/variants"

import * as css from "./styles.css"

type CardImageTitleZoomProps = InferReturn<typeof serializeCardImageTitleZoomSchema> & {
  className?: string
  customImageSizes?: Sizes
  customRatio?: Sprinkles["ratio"]
  ctaTheme?: RoundedCtaProps["theme"]
  ctaType?: RoundedCtaProps["type"]
  priority?: boolean
}

const IMAGE_SIZES = [{ breakpoint: breakpoints.md, ratio: 375 / 1440 }, { ratio: 335 / 375 }]

function CardImageTitleZoom({
  className,
  layout,
  suptitle,
  title,
  subtitle,
  asset,
  priority = false,
  cta,
  customRatio,
  customImageSizes,
}: CardImageTitleZoomProps) {
  return (
    <div className={clsx(className, css.CardImageTitleZoom, css.layout[layout])} data-comp="UI/CardImageTitleZoom">
      <Asset
        className={clsx(css.image)}
        {...asset}
        imageProps={{ priority }}
        ratio={customRatio ? customRatio : "5/8"}
        sizesFromBreakpoints={customImageSizes ? customImageSizes : IMAGE_SIZES}
      />
      <div className={clsx(css.texts)}>
        {suptitle && (
          <RichText
            className={clsx(
              text({
                design: "riposte-13",
                color: "grey-bg-page",
                transform: "uppercase",
                weight: "medium",
                letterSpacing: 1,
              })
            )}
            render={suptitle}
          />
        )}
        <div className={css.titleSubtitleContainer}>
          <RichText
            className={clsx(
              text({ design: "spezia-32", color: "grey-bg-page", transform: "uppercase", letterSpacing: 0.4 }),
              css.title
            )}
            render={title}
          />
          {subtitle && (
            <RichText
              className={clsx(
                text({
                  design: "riposte-13",
                  color: "white",
                  transform: "uppercase",
                  weight: "medium",
                  letterSpacing: 1,
                })
              )}
              render={subtitle}
            />
          )}
        </div>
        {cta && <RoundedCta asDiv className={css.cta} {...cta} />}
      </div>
    </div>
  )
}

export { CardImageTitleZoom, type CardImageTitleZoomProps }
