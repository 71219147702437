import type { UnlikelyImage, UnlikelyMedia } from "@unlikelystudio/commerce-connector"

import { handleZodSafeParse } from "~/lib/zod/utils/handle-zod-safe-parse"
import { shopifyImageSchema, type ImageFromSchema } from "~/components/ui/Image/_data/schema"
import imageLoader, { type LoaderParams } from "~/components/abstracts/Image/loader"

export type TImage = {
  src: string
  alt: string
  width?: number
  height?: number
  unoptimized?: boolean
}

export function serializeShopifyImages(
  images: (UnlikelyMedia | UnlikelyImage)[],
  loaderParams?: LoaderParams
): TImage[] {
  return images.reduce<TImage[]>((acc, image) => {
    if (!image) {
      return acc
    }

    const safeParsedImage = handleZodSafeParse(shopifyImageSchema, image, { errorContext: "serializeShopifyImages" })

    if (safeParsedImage) {
      acc.push(serializeImage(safeParsedImage, loaderParams))
    }

    return acc
  }, [])
}

export default function serializeImage(image: ImageFromSchema, loaderParams?: LoaderParams): TImage {
  const src = "filename" in image ? image.filename : image.url
  const alt = "alt" in image ? image.alt ?? "" : "altText" in image ? image.altText ?? "" : ""

  const dimensions = getImageDimensions(src, image)

  const isSvg = src?.indexOf(".svg") !== -1

  function getNewImageSrc(src: string) {
    const url = new URL(src)
    const newImageSrc = `${url?.origin}${url?.pathname}`

    if (!loaderParams) {
      return newImageSrc
    }

    const { src: paramsSrc, ...loaderParamsWithoutSrc } = loaderParams

    return imageLoader({
      src: paramsSrc ?? newImageSrc,
      ...loaderParamsWithoutSrc,
    })
  }

  return {
    src: getNewImageSrc(src) ?? src,
    width: dimensions?.width ?? undefined,
    height: dimensions?.height ?? undefined,
    alt,
    ...(isSvg ? { unoptimized: true, width: 0, height: 0 } : {}),
  }
}

function getImageDimensions(src: string, image: ImageFromSchema) {
  const dimensions = src.split("/")[5]?.split("x")
  const widthFromSrc = parseInt(dimensions?.[0] ?? "")
  const heightFromSrc = parseInt(dimensions?.[1] ?? "")

  return {
    width: "width" in image ? image.width : isNaN(widthFromSrc) ? undefined : widthFromSrc,
    height: "height" in image ? image.height : isNaN(heightFromSrc) ? undefined : heightFromSrc,
  }
}

export type mediaFromSchema = {
  url: string
  altText: string
  width: number
  height: number
  id: string
  mediaContentType: "IMAGE" | "VIDEO"
  createdAt: string
}
