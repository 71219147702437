import clsx from "clsx"

import type { InferReturn, PropsWithClassName } from "~/@types/generics"
import type serializeCardEdito from "~/components/ui/CardEdito/_data/serializer"
import { Image, type Sizes } from "~/components/ui/Image"
import RichText from "~/components/ui/RichText"
import RoundedCta from "~/components/ui/RoundedCta"
import { Stack } from "~/components/abstracts/Stack"
import WrapperWithLink from "~/components/abstracts/WrapperWithLink"

import { sprinkles, type Sprinkles } from "~/styles/sprinkles.css"
import { text, type TextVariants } from "~/styles/variants"

type CardEditoProps = PropsWithClassName<
  InferReturn<typeof serializeCardEdito> & {
    sizesFromBreakpoint: Sizes
    sprinklesCss?: Sprinkles
  }
>

function CardEdito({
  className,
  title,
  subtitle,
  cta,
  image,
  bgColor,
  textTheme,
  sizesFromBreakpoint,
  layout,
  sprinklesCss,
}: CardEditoProps) {
  const textColor: NonNullable<TextVariants>["color"] = textTheme === "black" ? "blue-deep" : "grey-bg-page"
  const backgroundColor = !image ? bgColor ?? "transparent" : "transparent"

  return (
    <WrapperWithLink
      link={cta}
      className={clsx(className, sprinkles({ position: "relative", display: "block", ...(sprinklesCss ?? {}) }))}
      style={{ backgroundColor }}
    >
      {image && (
        <Image
          sizesFromBreakpoints={sizesFromBreakpoint}
          {...image}
          imageHeight="100%"
          sprinklesCss={{ h: "100%", position: "absolute", inset: 0, zIndex: 10, w: "100%" }}
        />
      )}
      {(title || cta) && (
        <Stack
          direction="column"
          gap={30}
          sprinklesCss={{
            position: "relative",
            justifyContent: layoutToJustifyContent[layout],
            alignItems: "center",
            h: "100%",
            zIndex: 10,
            w: "100%",
            pY: 30,
            pX: 15,
          }}
        >
          <Stack direction="column" gap={15} responsiveGap={{ tablet: 20 }} sprinklesCss={{ alignItems: "center" }}>
            <RichText
              render={title}
              className={clsx(
                sprinkles({ overflowWrap: "break-word" }),
                text({
                  design: "spezia-24-48-fluid",
                  lineHeight: 1,
                  transform: "uppercase",
                  color: textColor,
                  textAlign: "center",
                })
              )}
            />
            <RichText
              render={subtitle}
              className={clsx(
                text({ design: "riposte-13-14", lineHeight: 1.45, color: textColor, textAlign: "center" })
              )}
            />
          </Stack>
          {cta?.children && <RoundedCta asDiv {...cta} />}
        </Stack>
      )}
    </WrapperWithLink>
  )
}

const layoutToJustifyContent = {
  between: "space-between",
  center: "center",
  bottom: "flex-end",
} as const

export { CardEdito, type CardEditoProps }
