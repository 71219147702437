"use client"

import { useRef } from "react"

import type { InferReturn } from "~/@types/generics"
import { Image } from "~/components/ui/Image"
import SliderComponent from "~/components/ui/Slider"
import { partnersSliderSliceSchema } from "~/components/slices/PartnersSlider/_data/schema"
import serializePartnersSliderSlice from "~/components/slices/PartnersSlider/_data/serializer"
import { WithSafeSliceData } from "~/components/slices/WithSafeSliceData"

import * as css from "./styles.css"

type PartnersSliderProps = InferReturn<typeof serializePartnersSliderSlice>

function PartnersSlider({ title, items }: PartnersSliderProps) {
  const customSliderRef = useRef<HTMLDivElement | null>(null)

  return (
    <div className={css.PartnersSlider} data-comp="Slices/PartnerSlider">
      <SliderComponent
        customSliderRef={customSliderRef}
        sliderContainerClassName={css.sliderContainer}
        containerProps={{
          gap: 55,
          className: css.sliderWrapper,
        }}
        title={{
          className: css.title,
          content: title,
        }}
        arrowNavigationPosition="center"
      >
        {items.map((item, index) => {
          return (
            <div key={`partner-${index}`} className={css.item}>
              <Image {...item.image} ratio={"1/1"} objectFit="contain" width={110} height={110} noBackground />
            </div>
          )
        })}
      </SliderComponent>
    </div>
  )
}

export { PartnersSlider, type PartnersSliderProps }

export default WithSafeSliceData(
  function (props) {
    const serializedProps = serializePartnersSliderSlice(props)

    return <PartnersSlider {...serializedProps} />
  },
  partnersSliderSliceSchema,
  "PartnersSliderSlice"
)
