import { z } from "zod"

import { imageSchema } from "~/components/ui/Image/_data/schema"
import { richtextSchema } from "~/components/ui/RichText/_data/schema"
import { defaultSliceSchema } from "~/components/slices/constants"

const partnersItemSchema = z.object({
  image: imageSchema,
})

export const partnersSliderSliceSchema = defaultSliceSchema("partners_slider").extend({
  title: richtextSchema,
  items: z.array(partnersItemSchema).min(1),
})
